import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export interface IErrorMessage {
  required: string;
  email: string;
  pattern: string;
  invalidPhone: string;
}
@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private errorMessages: IErrorMessage = {
    required: 'Enter the field',
    email: 'Please enter correct email',
    pattern: 'Please enter correct format',
    invalidPhone: 'Please enter correct phone number'
  };

  getErrorMessages(key: keyof IErrorMessage): string {
    return this.errorMessages[key];
  }

  constructor() {}

  static phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\d+$/.test(control.value);
      return valid ? null : { invalidPhone: { value: control.value } };
    };
  }

}
