import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { Router } from '@angular/router';
import { TourServicesService } from '@modules/service/services/tour-services.service';

export interface IMenuBase {
  title: string;
  route: string;
  disabled?: boolean;
}
export interface IMenuItem extends IMenuBase {
  subMenu?: {
    numberOfColumn?: number;
    type: SubMenuType;
    list: ISubMenuItem[];
  };
}

export type SubMenuType = 'default' | 'image';

export interface ISubMenuItem extends IMenuBase {
  imagePath?: string;
}

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderLayoutComponent {
  private _Router = inject(Router);
  private _TourServicesService = inject(TourServicesService);

  isMenuOpen: boolean = false;
  menuItems: IMenuItem[] = [
    {
      title: 'About Wowtrip',
      route: 'about-us',
      disabled: false,
      subMenu: {
        type: 'default',
        list: [
          { title: 'Why Wowtrip?', route: 'why-wowtrip' },
          { title: 'Our People', route: 'our-people' },
          { title: 'Testimonial', route: 'testimonial', disabled: false },
          { title: 'Our Clients', route: 'our-clients', disabled: false },
          // { title: 'Transport', route: 'transport', disabled: false }
        ]
      }
    },
    {
      title: 'Our services',
      route: 'tour-services',
      disabled: true,
      subMenu: {
        numberOfColumn: 2,
        type: 'image',
        list: [
          {
            title: 'Package Tour',
            route: 'package-tour',
            disabled: false,
            imagePath: 'assets/images/header/package-tour.png'
          },
          {
            title: 'Day trip',
            route: 'day-trip',
            disabled: false,
            imagePath: 'assets/images/header/shore-excursion.png'
          },
          {
            title: 'Shore Excursion',
            route: 'shore-excursion',
            disabled: true,
            imagePath: 'assets/images/header/private-tour.png'
          },
          {
            title: 'Education trip',
            route: 'education-trip',
            disabled: true,
            imagePath: 'assets/images/header/education-trip.jpg'
          }
        ]
      }
    },
    {
      title: 'Mice & Events',
      route: 'mice-and-events',
      disabled: true,
      subMenu: {
        numberOfColumn: 1,
        type: 'image',
        list: [
          {
            title: 'Activities in Da Nang/Hoi An',
            route: 'danang-hoian-activities',
            disabled: false,
            imagePath: 'assets/images/header/activities.png'
          },
          {
            title: 'Meeting & incentive service',
            route: 'meeting-incentive-service',
            disabled: false,
            imagePath: 'assets/images/header/meeting&service.jpg'
          },
          {
            title: 'Optional tour for group',
            route: 'optional-tour-for-group',
            disabled: false,
            imagePath: 'assets/images/header/tour.jpg'
          }
        ]
      }
    },
    { title: 'Successful events', route: 'successful-events', disabled: true }
    // { title: 'Transport', route: 'transport', disabled: false },
  ];

  onClickMenu(menuItem: IMenuItem) {
    this._Router.navigate([menuItem.route]).then(() => {
      // UtilsService.scrollToTop();
    });
  }

  onClickSubMenu(menuItem: IMenuItem, subMenuItem: ISubMenuItem) {
    this._Router.navigate([menuItem.route, subMenuItem.route]).then(() => {
      this._TourServicesService.scrollToTour$.next();
    });
  }

  onClickMobileMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  ScrollToId(id: string) {
    UtilsService.scrollToId(id);
  }
}
