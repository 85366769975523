import { Injectable } from '@angular/core';
import {
  Duration,
  IAgeGroup,
  IItinerarySummary,
  ITourDetail
} from '@modules/service/models/tour-service.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Subject, tap } from 'rxjs';
import { BaseService } from '@shared/services/base.service';
import { IPagination } from '@shared/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class TourServicesService extends BaseService {
  tourDetail$: BehaviorSubject<ITourDetail | null> = new BehaviorSubject<ITourDetail | null>(null);
  scrollToTour$: Subject<void> = new Subject<void>();
  tourId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // url = window.location.host + '/api/v1/';
  itinerarySummary: IItinerarySummary[] = [
    {
      imageUrl: 'assets/images/services/itinerary/hanoi.png',
      schedule: [
        {
          day: 'Day 1',
          itinerary: 'Arrival',
          description: 'The guide greets visitors on arrival and accompanies them to the hotel.'
        },
        {
          day: 'Day 2',
          itinerary: 'Hanoi',
          description:
            'A full-day tour of Hanoi and its historical sights. Start off at Ba Dinh square, home to Ho Chi Minh’s mausoleum, to learn about ‘Uncle Ho’s life. Then, head to the beautiful Temple of Literature, the oldest university in Hanoi, built in homage to the Chinese scholar Confucius. Spend time in the grounds and admire the architecture. Finish the day with a demonstration of how to make ‘egg coffee’, a Hanoi delicacy, before trying the drink in a quaint coffee shop.'
        }
      ]
    },
    {
      imageUrl: 'assets/images/services/itinerary/halong.png',
      schedule: [
        {
          day: 'Day 3',
          itinerary: 'Ha Noi - Halong',
          description:
            'Transfer from Hanoi to Ha long to embark upon a Calypso cruise. Sail through the stunning karst mountains of Halong Bay to Lan Ha Bay. Here, visit the Light and Dark Water cave before exploring by kayak or by bamboo boat. After returning to the boat, it’s time to enjoy the magnificent sunset over the Gulf of Tonkin. Witness a cooking demonstration before tucking into a Vietnamese feast. Enjoy the rest of the night admiring the view from the top deck, having a drink and enjoying the karaoke, or trying your luck at some squid fishing.'
        },
        {
          day: 'Day 4',
          itinerary: 'Ha Long - Hoi An',
          description:
            'Wake up with some Tai Chi and a light breakfast before heading to Cat Ba island. Visit Army Hospital Cave on the island to learn about resistance during the Vietnam War, all the while admiring the scenic views of Cat Ba National Park. Take the cruise back to Hanoi harbour and depart from the airport to Danang before a short coach ride to Hoi An.'
        }
      ]
    },
    {
      imageUrl: 'assets/images/services/itinerary/hoian.png',
      schedule: [
        {
          day: 'Day 5',
          itinerary: 'Hoi An',
          description:
            'Meet the host family in the Hoi An countryside. Together, do some organic farming activities related to gardening, cooking, and other tasks in the community. Also, go on a picturesque bicycle to explore the charming scenery of the surrounding rice fields, which will be discovered more on a boat tour. Enjoy a wood crafting workshop before banh xeo for lunch with the family. Then, back to the flower-lined streets of Hoi An town to take a tour of its historical sites and monuments before heading to a teahouse. The teahouse supports those with disabilities in the area.'
        },
        {
          day: 'Day 6',
          itinerary: 'Hoi An - Ho Chi Minh City',
          description:
            'Free time before transfer to Danang airport to fly to Ho Chi Minh City, where the guide will be waiting.'
        }
      ]
    },
    {
      imageUrl: 'assets/images/services/itinerary/danang.png',
      schedule: [
        {
          day: 'Day 7',
          itinerary: 'Hoian - Danang',
          description:
            'Full-day tour of Ho Chi Minh City. Begin by visiting the ornate tomb of Le Van Duyet, set in a serene park, where there are many relics to admire. Then see the hustle and bustle of Ba Chieu market for a taste of local life, where families buy their food and wares. Transition back into tranquillity at Jade Emperor Pagoda before visiting Le Van Tam Park, once a cemetery and now a public green paradise. Finish the tour with a trip to an inconspicuous apartment building which surprisingly houses a whole world of activity inside – hipster cafes, boutique shops, and fashionable eateries await.'
        },
        {
          day: 'Day 8',
          itinerary: 'Ho Chi Minh City',
          description: 'Free day of exploration in Ho Chi Minh. Optional tours are available.'
        },
        {
          day: 'Day 8',
          itinerary: 'Ho Chi Minh City',
          description: 'Free time until airport transfer for departure flight.'
        }
      ]
    }
  ];

  getAllTour(
    pagination: IPagination = {
      page: 1,
      pageSize: 5
    },
    type: string = 'package'
  ) {
    return this.get<any>(`tours`, 'v1', { ...pagination, type: type });
    // return this._http.get<ApiResponse>(`https://wowtrip.vn:8442/api/v1/tours`);
  }

  getTourDetail(id: string) {
    return this.get<any>(`tours/detail/${id}`, 'v1').pipe(
      tap((tourDetail) => this.tourDetail$.next(tourDetail))
    );
    // return this._http
    //   .get<ApiResponse>(`https://wowtrip.vn:8442/api/v1/tours/detail/${id}`)
    //   .pipe(tap((tourDetail) => this.tourDetail$.next(tourDetail.data)));
  }

  getDate(tourDetail: ITourDetail) {
    const duration = tourDetail.duration;
    if (typeof duration !== 'number') {
      const startDay = new Date(duration.day).getDay();
      const endDay = new Date(duration.night).getDay();
      return endDay - startDay;
    }
    return 0;
  }

  getDailyPrice(id: string) {
    return this.get<{ date: string; price: number }[]>(`tours/prices/${id}`, 'v1');
    // return this._http.get<ApiResponse>(`https://wowtrip.vn:8442/api/v1/tours/daily-price/${id}`);
  }

  getAgeGroup() {
    return this.get<IAgeGroup[]>('tours/group-age', 'v1').pipe(
      map((res) =>
        res.map((group: IAgeGroup) => ({
          ...group,
          quantity: 0,
          price: 0
        }))
      )
    );
  }

  paymentContact(data: any) {
    return this.post<any>('tours/contacts', 'v1', data, undefined, {
      showSuccessMessage: true,
      showErrorMessage: true,
      customErrorMessage: 'Something went wrong ! Please try again later'
    });
  }

  paymentSubmit(data: any) {
    return this.post<any>('payment', 'v1', data, undefined, {
      showSuccessMessage: false,
      showErrorMessage: false
    });
  }

  constructor(private _http: HttpClient) {
    super(_http);
  }
}
