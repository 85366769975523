import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourServicesService } from '@modules/service/services/tour-services.service';
import { IAgeGroup, ITourDetail } from '@modules/service/models/tour-service.model';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import * as moment from 'moment';
import { CalendarComponent } from '@shared/components/calendar/calendar.component';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { differenceInCalendarDays } from 'date-fns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@shared/services/utils.service';
import { map } from 'rxjs';
import { TourDetailsPreviewDialogData } from '@admin/modules/tour-management/dialogs/tour-details-preview-dialog/tour-details-preview-dialog.component';
import _, { get } from 'lodash';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactFormModalComponent } from '@shared/components/tour-cart/contact-form-modal/contact-form-modal.component';
import { Overlay } from '@angular/cdk/overlay';
@Component({
  selector: 'app-tour-cart',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    NzDatePickerModule,
    CalendarComponent,
    NzCalendarModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  templateUrl: './tour-cart.component.html',
  styleUrls: ['./tour-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class TourCartComponent implements OnInit {
  @Input() tourDetail: ITourDetail;
  @Input() isSticky: boolean = true;
  @Output() onClickPaymentHidden = new EventEmitter<boolean>();
  quantityFormGroup: FormGroup<{ [p: string]: FormControl<any> }>;
  dailyPrice: any[];
  minPrice: number;
  today = new Date();
  selectedDate: string = '';
  selectedPrice: number;
  firstDate: string;
  lastDate: string;

  data: any;
  nearestTourDate: Date;
  tourDate: string;
  private _cdr = inject(ChangeDetectorRef);
  constructor(
    private _TourServicesService: TourServicesService,
    private _ActivatedRoute: ActivatedRoute,
    private _MatDialog: MatDialog,
    public overlay: Overlay,
    private _UtilsService: UtilsService
  ) {}
  ngOnInit() {
    this.getDailyPrice();
  }

  get discountByGroupAge(): { ageGroup: IAgeGroup; discount: number; id: string }[] {
    return this.data?.discountByGroupAge ?? [];
  }

  initForm() {
    const formData = this.discountByGroupAge?.reduce(
      (acc, curr) => {
        const initValue = curr.ageGroup.name === 'Adult' ? 1 : 0;
        acc[curr.ageGroup.id] = new FormControl<number>(initValue, [
          Validators.required,
          Validators.min(initValue)
        ]);
        return acc;
      },
      {} as { [key: string]: FormControl }
    );
    this.quantityFormGroup = new FormGroup(formData);
  }

  getQuantityFormControl(id: string) {
    return this.quantityFormGroup.get(id) as FormControl<number>;
  }

  get priceByTouristCount() {
    return this.data?.priceByTouristCount;
  }

  getDailyPrice() {
    const todayDate = UtilsService.formatDate(this.today.toString());
    this._TourServicesService.getDailyPrice(this.tourDetail.tour.id).subscribe((res) => {
      this.dailyPrice = res;
      this.getFirstAndLastDate(res);
      console.log(this.dailyPrice);
      const tourDate = this.dailyPrice?.filter((item) => {
        const itemDate = item.date;
        return (
          item.date === itemDate &&
          Number(itemDate.split('-').join('')) - Number(todayDate.split('-').join('')) >=
            this.tourDetail.dayInAdvance &&
          item.status.name === 'Upcoming'
        );
      });
      const smallestDate = tourDate?.reduce((acc, currentValue) => {
        return new Date(currentValue.date) < new Date(acc.date) ? currentValue : acc;
      });
      this.nearestTourDate = new Date(smallestDate.date);
      this.smallestTourPrice();
      this._cdr.detectChanges();
    });
  }

  smallestTourPrice() {
    const dailyPrice = this.dailyPrice;
    if (dailyPrice) {
      const minPrice = dailyPrice.reduce((min, item) => {
        const price = item.priceByTouristCount[0].pricePerTourist;
        return price < min ? price : min;
      }, dailyPrice[0].priceByTouristCount[0].pricePerTourist);
      if (isNaN(minPrice)) {
        this.minPrice = 0;
      } else {
        this.minPrice = minPrice;
      }
    }
  }

  getFirstAndLastDate(dailyPrice: any[]) {
    if (dailyPrice) {
      this.firstDate = this.dailyPrice[this.dailyPrice.length - 1].date;
      this.lastDate = this.dailyPrice[0].date;
    }
  }

  get totalTourist() {
    const ageGroup = this.discountByGroupAge.find((ageGroup) => ageGroup.ageGroup.name === 'Adult');
    if (ageGroup) {
      const control = this.getQuantityFormControl(ageGroup?.ageGroup?.id);
      return control.value;
    }
    return 0;
    // return _.reduce(this.quantityFormGroup.controls, (acc, curr) => acc + curr.value, 0);
  }

  renderPriceForDay(date: any): string | undefined {
    if (date) {
      const formatDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z').format('YYYY-MM-DD');
      const dateWithPrice = this.dailyPrice?.find((data) => data.date == formatDate);
      return dateWithPrice
        ? '$' + dateWithPrice.priceByTouristCount[0].pricePerTourist.toString()
        : undefined;
      // TODO: WILL FIX LATER
    }
    return undefined;
  }

  onChangeDate(date: any) {
    const formatDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z').format('YYYY-MM-DD');
    this.selectedDate = formatDate;
    const dateWithPrice = this.dailyPrice.find((data) => data.date == formatDate);
    this.data = dateWithPrice;
    this.tourDate = this.data.id;
    this.initForm();
    this.selectedPrice = dateWithPrice ? dateWithPrice.priceByTouristCount[0].pricePerTourist : 0;
  }

  increase(ageGroup: IAgeGroup) {
    const control = this.getQuantityFormControl(ageGroup.id);
    control.setValue(control.value + 1);
    this._cdr.detectChanges();
  }

  decrease(ageGroup: IAgeGroup) {
    const control = this.getQuantityFormControl(ageGroup.id);
    if (ageGroup.name === 'Adult') {
      if (control.value > 1) {
        control.setValue(control.value - 1);
      }
    } else if (control.value > 0) {
      control.setValue(control.value - 1);
    }
    this._cdr.detectChanges();
  }

  getPrice(): number {
    this.priceByTouristCount.sort((a: any, b: any) => a.touristCount - b.touristCount);

    for (let item of this.priceByTouristCount) {
      if (this.totalTourist <= item.touristCount) {
        this.selectedPrice = item.pricePerTourist;
        return item.pricePerTourist;
      }
    }
    const lastItem = this.priceByTouristCount[this.priceByTouristCount.length - 1];
    this.selectedPrice = lastItem.pricePerTourist;
    return lastItem.pricePerTourist;
  }

  displayPricePerGroup(item: { ageGroup: IAgeGroup; discount: number; id: string }): number {
    return (
      this.getPrice() * this.quantityFormGroup.get(item.ageGroup.id)!.value * (1 - item.discount)
    );
  }

  get totalPrice(): number {
    return _.reduce(
      this.discountByGroupAge,
      (acc, curr) => acc + this.displayPricePerGroup(curr),
      0
    );
  }

  private getQuantityValue() {
    const quantity = _.reduce(
      this.quantityFormGroup.getRawValue() as { [key: string]: number },
      (acc, value, key) => {
        acc.push({
          quantity: value,
          ageGroup: key
        });
        return acc;
      },
      [] as { ageGroup: string; quantity: number }[]
    );
    return quantity;
  }

  checkIsNaN(value: number): boolean {
    return Number.isNaN(value);
  }

  disabledDate = (date: Date): boolean => {
    const todayDate = UtilsService.formatDate(this.today.toString());
    const dateInCalendar = moment(date).format('YYYY-MM-DD');
    const isDateWithPrice = !this.dailyPrice?.some((item) => {
      return (
        item.date === dateInCalendar &&
        moment(item.date).valueOf() - moment(todayDate).valueOf() >= this.tourDetail.dayInAdvance &&
        item.status.name === 'Upcoming'
      );
    });
    // differenceInCalendarDays(date, this.today) < 0 - this code disabled past and today;
    // isDateWithPrice is startDate
    return isDateWithPrice || differenceInCalendarDays(date, this.today) < 0;
  };
  saveToLocalStorage() {
    const ageGroupData = this.data?.discountByGroupAge.map((group: any, index: number) => ({
      ...group,
      price: this.displayPricePerGroup(group),
      quantity: this.getQuantityValue()[index],
      tourPrice: this.selectedPrice
    }));
    // localStorage.setItem('ageGroupData', JSON.stringify(this.ageGroup));
    localStorage.setItem('totalPrice', JSON.stringify(this.totalPrice));
    localStorage.setItem('selectedPrice', JSON.stringify(this.selectedPrice));
    localStorage.setItem('selectedDate', JSON.stringify(this.selectedDate));
    localStorage.setItem('tourDate', JSON.stringify(this.tourDate));
    localStorage.setItem('ageGroupDetails', JSON.stringify(this.getQuantityValue()));
    localStorage.setItem('ageGroupData', JSON.stringify(ageGroupData));
  }

  onCLickNextPage(value?: boolean) {
    this._ActivatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this._TourServicesService.tourId$.next(params['itinerary']);
    });
    this.saveToLocalStorage();
    this.onClickPaymentHidden.emit(value);
  }

  openContactModal() {
    this._MatDialog.open(ContactFormModalComponent, {
      data: this.tourDetail,
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }
}
