import { inject, Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as devEnvironment } from '../../../environments/environment.development';
import { environment as prodEnvironment } from '../../../environments/environment.prod';
import { catchError, map, Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageDataOptions } from 'ng-zorro-antd/message/typings';
import { NzNotificationDataOptions, NzNotificationService } from 'ng-zorro-antd/notification';

export class ResponseData<T> {
  data?: T;
  code: number;
  message: string;
  error: any | null;
}

export type ApiVersion = 'v1' | 'v2';

export type MessageOptionsType = {
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  options?: NzMessageDataOptions;
  customSuccessMessage?: string;
  customErrorMessage?: string;
};

export type NotificationOptionsType = {
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  customSuccessTitle?: string;
  customErrorTitle?: string;
  customSuccessMessage?: string;
  customErrorMessage?: string;
  options?: NzNotificationDataOptions;
};

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  private defaultMessageOptions: MessageOptionsType = {
    showSuccessMessage: false,
    showErrorMessage: false
  };
  private defaultNotificationOptions: NotificationOptionsType = {
    showSuccessMessage: false,
    showErrorMessage: true,
    options: {
      nzDuration: 4000,
      nzPauseOnHover: true,
      nzPlacement: 'bottomLeft'
    }
  };
  get apiEndpoint(): string {
    return isDevMode() ? devEnvironment.apiUrl : prodEnvironment.apiUrl;
  }

  public _NzMessageService: NzMessageService = inject(NzMessageService);
  public _NzNotiService: NzNotificationService = inject(NzNotificationService);
  constructor(public http: HttpClient) {
    console.log('BaseService: ' + this.apiEndpoint);
  }

  getRequestUrl(url: string, version: ApiVersion): string {
    return `${this.apiEndpoint}${version}/${url}`;
  }

  public get<T>(
    url: string,
    version: ApiVersion,
    params?: any,
    headers?: HttpHeaders,
    messageOps: MessageOptionsType = this.defaultMessageOptions
  ): Observable<T> {
    return this.http
      .get<ResponseData<T>>(this.getRequestUrl(url, version), { params, headers })
      .pipe(
        map((res: ResponseData<T>) => {
          if (messageOps?.showSuccessMessage && res?.message) {
            this._NzMessageService.success(messageOps?.customSuccessMessage ?? res.message);
          }
          return res.data as T;
        }),
        catchError((error) => {
          this._NzMessageService.error(
            error?.error?.message ?? 'Error occurred',
            messageOps.options
            // messageOps?.customErrorMessage ?? error?.error?.message,
            // messageOps.options
          );
          throw error;
        })
      );
  }

  public post<T>(
    url: string,
    version: ApiVersion,
    data?: any,
    headers?: HttpHeaders,
    messageOps: NotificationOptionsType = this.defaultMessageOptions
  ): Observable<T> {
    return this.http
      .post<ResponseData<T>>(this.getRequestUrl(url, version), data, { headers })
      .pipe(
        map((res: ResponseData<T>) => {
          if (messageOps?.showSuccessMessage && res?.message) {
            this._NzNotiService.success(
              'Success',
              messageOps?.customSuccessMessage ?? res.message,
              messageOps.options
            );
          }
          return res.data as T;
        }),
        catchError((error) => {
          if (messageOps?.showErrorMessage)
            this._NzMessageService.error(
              // error?.error?.message ?? 'Error occurred',
              // messageOps.options
              messageOps?.customErrorMessage ?? error?.error?.message,
              messageOps.options
            );
          // this._NzNotiService.error(
          //   messageOps?.customErrorTitle ?? 'Error Occurred',
          //   messageOps?.customErrorMessage ?? error?.error?.message,
          //   messageOps.options
          // );
          throw error;
        })
      );
  }

  public postFullData<T>(
    url: string,
    version: ApiVersion,
    data?: any,
    headers?: HttpHeaders,
    messageOps: MessageOptionsType = this.defaultMessageOptions
  ): Observable<ResponseData<T>> {
    return this.http
      .post<ResponseData<T>>(this.getRequestUrl(url, version), data, { headers })
      .pipe(
        catchError((error) => {
          this._NzMessageService.error(
            error?.error?.message ?? 'Error occurred',
            messageOps.options
          );
          throw error;
        })
      );
  }

  public put<T>(url: string, version: ApiVersion, data: any, headers?: HttpHeaders): Observable<T> {
    return this.http
      .put<ResponseData<T>>(this.getRequestUrl(url, version), data, { headers })
      .pipe(map((result) => result as T));
  }

  public patch<T>(
    url: string,
    version: ApiVersion,
    data: any,
    headers?: HttpHeaders,
    messageOps: MessageOptionsType = this.defaultMessageOptions
  ): Observable<T> {
    return this.http
      .patch<ResponseData<T>>(this.getRequestUrl(url, version), data, { headers })
      .pipe(
        map((result) => result as T),
        catchError((error) => {
          this._NzMessageService.error(
            error?.error?.message ?? 'Error occurred',
            messageOps.options
          );
          throw error;
        })
      );
  }

  public delete<T>(
    url: string,
    version: ApiVersion,
    data: any,
    headers?: HttpHeaders,
    messageOps: MessageOptionsType = this.defaultMessageOptions
  ): Observable<T> {
    return this.http
      .request<T>('delete', this.getRequestUrl(url, version), {
        headers,
        body: data
      })
      .pipe(
        map((result) => result as T),
        catchError((error) => {
          this._NzMessageService.error(
            error?.error?.message ?? 'Error occurred',
            messageOps.options
          );
          throw error;
        })
      );
  }

  public getDetail<T>(
    url: string,
    version: ApiVersion,
    id: string,
    nameParam: string,
    headers?: any,
    messageOps: MessageOptionsType = this.defaultMessageOptions
  ): Observable<T> {
    const httpParams = new HttpParams().set(nameParam, id);
    return this.http
      .get<ResponseData<T>>(this.getRequestUrl(url, version), {
        headers,
        params: httpParams
      })
      .pipe(
        map((result) => result as T),
        catchError((error) => {
          this._NzMessageService.error(
            error?.error?.message ?? 'Error occurred',
            messageOps.options
          );
          throw error;
        })
      );
  }
}
