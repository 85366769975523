import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { EmptyLayoutComponent } from './empty-layout/empty-layout.component';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { FooterLayoutComponent } from './footer-layout/footer-layout.component';
import { RouterModule } from '@angular/router';
import { BannerLayoutComponent } from '@modules/layouts/banner-layout/banner-layout.component';
import { ContactUsLayoutComponent } from './contact-us-layout/contact-us-layout.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { OrderLayoutComponent } from './order-layout/order-layout.component';
import { OrderSummaryComponent } from '@shared/components/order-summary/order-summary.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ProgressBarComponent } from '@shared/components/progress-bar/progress-bar.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    MainLayoutComponent,
    EmptyLayoutComponent,
    HeaderLayoutComponent,
    FooterLayoutComponent,
    BannerLayoutComponent,
    ContactUsLayoutComponent,
    OrderLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    NzNotificationModule,
    NzMenuModule,
    OrderSummaryComponent,
    NzSpinModule,
    ProgressBarComponent,
    RecaptchaModule
  ],
  exports: [
    MainLayoutComponent,
    EmptyLayoutComponent,
    HeaderLayoutComponent,
    FooterLayoutComponent,
    BannerLayoutComponent,
    OrderLayoutComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutsModule {}
