<div class="container">
   <div class="row">
      <div class="col col-xl-8">
         <ng-content></ng-content>
      </div>
      <div class="col col-xl-4">
         <app-order-summary></app-order-summary>
      </div>
   </div>
</div>
