import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '@admin/auth/services/auth.service';

@Injectable({ providedIn: 'root' })
export class CheckLoginGuard implements CanActivate {
  private _Router = inject(Router);
  private _AuthService = inject(AuthService);
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // const isLogin = Boolean(this._AuthService.getUserInfo()); // replace with your logic
    if (this._AuthService.isLogged) {
      // do something
      return of(true);
    } else {
      // do something
      this._Router.navigate(['/auth/login']);
      return of(false);
    }
  }
}
