<div class="header-layout">
  <div class="px-xxl-5 h-64px">
    <div class="menu">
      <div routerLink="/" class="logo">
        <img src="assets/imgs/Logo-wow-trip.png" alt="" />
      </div>
      <div
        *ngFor="let menuItem of menuItems"
        [routerLink]="menuItem.route"
        (click)="$event.stopPropagation(); onClickMenu(menuItem)"
        routerLinkActive="active"
        class="menu-item"
      >
        {{ menuItem.title }}
        <div
          (click)="$event.stopPropagation()"
          class="sub-menu"
          [ngClass]="{
            'image-style': menuItem.subMenu?.type === 'image',
            'text-style': menuItem.subMenu?.type !== 'image',
            'two-column': menuItem.subMenu?.numberOfColumn === 2,
            'one-column': menuItem.subMenu?.numberOfColumn !== 2
          }"
          *ngIf="menuItem.subMenu?.list?.length"
        >
          <div
            class="sub-menu-item"
            [class.disabled-half]="subMenuItem.disabled"
            [routerLink]="[menuItem.route, subMenuItem.route]"
            (click)="$event.stopPropagation(); onClickSubMenu(menuItem, subMenuItem)"
            routerLinkActive="active"
            *ngFor="let subMenuItem of menuItem?.subMenu?.list"
          >
            <ng-container *ngIf="menuItem.subMenu?.type !== 'image'; else imageTemplate">{{
              subMenuItem.title
            }}</ng-container>
            <ng-template #imageTemplate>
              <img
                class="sub-menu-item--image"
                width="100%"
                height="100%"
                [src]="subMenuItem.imagePath"
              />
              <div class="text-overlay">
                <span>{{ subMenuItem.title }}</span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div (click)="ScrollToId('contact-us')" class="menu-item">Contact</div>

      <div class="flex-grow-1"></div>
      <div class="action">
<!--        <div class="action-item language-switch">-->
<!--          <img ngSrc="assets/icons/english-flag.svg" alt="" height="16" width="40" />-->
<!--          <img ngSrc="assets/icons/CaretDown.svg" alt="" height="16" width="16" />-->
<!--        </div>-->
        <div class="action-item">
          <img ngSrc="assets/icons/map.svg" alt="" height="24" width="24" />
          <span>Cart</span>
        </div>
        <div (click)="onClickMobileMenu()" class="list-btn d-none">
          <img *ngIf="!isMenuOpen" ngSrc="assets/icons/List.svg" alt="" height="24" width="24" />
          <img *ngIf="isMenuOpen" ngSrc="assets/icons/x-icon.svg" alt="" height="24" width="24" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isMenuOpen" class="mobile-menu">
    <ul nz-menu nzMode="inline" class="mobile-menu-list">
      <ng-container *ngFor="let menuItem of menuItems">
        <ng-container *ngIf="menuItem.subMenu; else noSubMenu">
          <li nz-submenu nzTitle="{{ menuItem.title }}">
            <ul
              class="sub-menu-list"
              [ngClass]="{
                'image-style': menuItem.subMenu?.type === 'image',
                'text-style': menuItem.subMenu?.type !== 'image',
                'two-column': menuItem.subMenu?.numberOfColumn === 2,
                'one-column': menuItem.subMenu?.numberOfColumn !== 2
              }"
            >
              <li
                class="sub-menu-item"
                [class.disabled-half]="subMenuItem.disabled"
                *ngFor="let subMenuItem of menuItem.subMenu.list"
                [routerLink]="[menuItem.route, subMenuItem.route]"
                (click)="onClickMobileMenu()"
                routerLinkActive="active"
                nz-menu-item
              >
                <ng-container *ngIf="menuItem.subMenu?.type !== 'image'; else imageTemplate">{{
                  subMenuItem.title
                }}</ng-container>
                <ng-template #imageTemplate>
                  <div class="image-container">
                    <img
                      class="sub-menu-item--image"
                      width="100%"
                      height="100%"
                      [src]="subMenuItem.imagePath"
                    />
                    <div class="text-overlay">
                      <span>{{ subMenuItem.title }}</span>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
          </li>
        </ng-container>
        <ng-template #noSubMenu>
          <li
            (click)="onClickMobileMenu()"
            style="padding: 12px 24px; margin: 0"
            nz-menu-item
            [routerLink]="menuItem.route"
          >
            {{ menuItem.title }}
          </li>
        </ng-template>
      </ng-container>
    </ul>
    <ul nz-menu nzMode="inline" class="mobile-menu-list">
      <li nz-submenu [nzTitle]="languageTemplate"></li>
      <ng-template #languageTemplate>
        <div class="d-flex align-items-center gap-1">
          <img ngSrc="assets/icons/english-flag.svg" alt="" height="16" width="40" />
          <p class="m-0">English</p>
        </div>
      </ng-template>
    </ul>
  </div>
</div>
