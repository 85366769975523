import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeEvent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-tour-important-note-form',
  standalone: true,
  imports: [CommonModule, CKEditorModule],
  templateUrl: './tour-important-note-form.component.html',
  styleUrls: ['./tour-important-note-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourImportantNoteFormComponent implements OnInit {
  @Input() note = '';
  public Editor = ClassicEditor;
  editedNote = '';
  @Input() configCkEditor = {
    toolbar: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList']
  };

  ngOnInit() {}

  onChange($event: ChangeEvent<ClassicEditor>) {
    const data = $event.editor.getData();
    this.editedNote = data;
    console.log(this.editedNote);
  }
}
