<div class="tour-cart-wrapper tour-cart-wrapper--sticky">
  <div class="tour-cart">
    <div class="d-flex flex-column justify-content-center gap-3">
      <h1 *ngIf="totalPrice !== 0; else avg" class="total-price">
        ${{ totalPrice | number: '1.0-2' }}
      </h1>
      <ng-template #avg>
        <h1 class="total-price">From ${{ minPrice ?? 0 }}</h1>
      </ng-template>
      <div class="date-picker-wrapper">
        <!--        <span *ngIf="dailyPrice" class="text">-->
        <!--          From {{ firstDate | date: 'MMM dd, yyyy' }} To {{ lastDate | date: 'MMM dd, yyyy' }}</span-->
        <!--        >-->
        <div class="">
          <nz-date-picker
            [nzInputReadOnly]="true"
            class="custom-date-picker w-100"
            [nzFormat]="'EEEE, MMMM d, YYYY'"
            [nzAllowClear]="false"
            (ngModelChange)="onChangeDate($event)"
            [(ngModel)]="selectedDate"
            [nzShowToday]="false"
            [nzDateRender]="tplRender"
            [nzDropdownClassName]="'custom-date-picker-dropdown'"
            [nzDisabledDate]="disabledDate"
            [nzSuffixIcon]="customcalendar"
            [nzDefaultPickerValue]="nearestTourDate"
          ></nz-date-picker>

          <ng-template #tplRender let-date>
            <div class="ant-picker-cell-inner">
              {{ date.getDate() }}
            </div>
            <div class="ant-picker-cell-inner-price">
              {{ renderPriceForDay(date) }}
            </div>
          </ng-template>

          <ng-template #customcalendar>
            <i style="color: #090a0b" class="fa-regular fa-calendar-days"></i>
          </ng-template>
        </div>
      </div>
      <div class="quantity-wrapper">
        <p class="text">Quantity</p>
        <form *ngIf="discountByGroupAge.length > 0; else elseBlock" [formGroup]="quantityFormGroup">
          <div class="quantity-box">
            <ng-container *ngFor="let data of discountByGroupAge ?? []; let i = index">
              <div class="d-flex justify-content-between flex-gap-8px">
                <div>
                  <h1 class="age-group-title">
                    {{ data.ageGroup.name }} ({{ data.ageGroup.minAge }}-{{ data.ageGroup.maxAge }})
                  </h1>
                  <p class="text">${{ displayPricePerGroup(data) | number: '1.0-2' }}</p>
                </div>
                <div class="quantity-form">
                  <button
                    style="border-top-right-radius: 0; border-bottom-right-radius: 0"
                    class="quantity-form-btn"
                    (click)="decrease(data.ageGroup)"
                  >
                    -
                  </button>
                  <input
                    min="0"
                    [id]="data.ageGroup.id"
                    [formControlName]="data.ageGroup.id"
                    [name]="data.ageGroup.id"
                    class="quantity-form-input"
                    type="number"
                  />
                  <button
                    style="border-top-left-radius: 0; border-bottom-left-radius: 0"
                    class="quantity-form-btn"
                    (click)="increase(data.ageGroup)"
                  >
                    +
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
        <ng-template #elseBlock>
          <p>Please choose a date first</p>
        </ng-template>
      </div>
      <div class="button-controller">
        <button (click)="openContactModal()" class="button button-primary-light w-100">
          Contact
        </button>
        <button
          [disabled]="totalPrice <= 0 || checkIsNaN(totalPrice)"
          [routerLink]="'/cart/book/' + tourDetail.tour.id"
          (click)="onCLickNextPage(true)"
          class="button button-primary w-100"
        >
          BOOK NOW
        </button>
      </div>
    </div>
  </div>
</div>
