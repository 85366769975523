<div class="contact-us-banner" id="contact-us">
  <div class="container">
    <div class="d-flex align-items-center justify-content-center flex-gap-24px flex-column py-56px">
      <div class="text-container">
        <h1 class="title">Let the journey begin!</h1>
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="contactForm" class="contact-us-form">
        <div class="form-container row">
          <div class="form-input col-lg-6 col-sm-12">
            <label class="input-label">Your Name</label>
            <div class="input-text_wrapper">
              <input
                type="text"
                class="input-text"
                placeholder="Type your first & last name *"
                formControlName="fullName"
              />
            </div>
            <div
              *ngIf="
                contactForm.get('fullName')?.invalid &&
                (contactForm.get('fullName')?.dirty || contactForm.get('fullName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('fullName')?.errors?.['required']">
                Please enter your name
              </p>
              <p *ngIf="contactForm.get('fullName')?.errors?.['pattern']">
                Please enter your name without special characters and numbers.
              </p>
            </div>
          </div>
          <div class="form-input col-lg-6 col-sm-12">
            <label class="input-label">Email Address</label>
            <div class="input-text_wrapper">
              <input
                type="text"
                class="input-text"
                placeholder="Type your email address *"
                formControlName="email"
              />
            </div>
            <div
              *ngIf="
                contactForm.get('email')?.invalid &&
                (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('email')?.errors?.['required']">Please enter your email</p>
              <p *ngIf="contactForm.get('email')?.errors?.['email']">Please enter valid email</p>
            </div>
          </div>
        </div>
        <div class="form-container row">
          <div class="form-input col-lg-6 col-sm-12">
            <label class="input-label">How can we help you?</label>
            <div class="input-text_wrapper">
              <input
                type="text"
                class="input-text"
                placeholder="Type your company name"
                formControlName="compName"
              />
            </div>
          </div>
          <div class="form-input col-lg-6 col-sm-12">
            <label class="input-label">How many guests and what ages?</label>
            <div class="input-text_wrapper">
              <input
                type="text"
                class="input-text"
                placeholder="Please enter number of guest and ages"
                formControlName="guestNumbers"
              />
            </div>
          </div>
        </div>
        <div class="form-container">
          <div style="flex: 1" class="form-input">
            <label class="input-label">What would your client like to do?</label>
            <div class="input-text_wrapper">
              <textarea type="text" class="textarea-text" formControlName="question"></textarea>
            </div>
          </div>
        </div>
        <p class="text">
          Please include requirment such as special interests, preferences, dietary or mobility
          restrictions, what level of hotel they prefer, total budget excluding airfare and anything
          else that will help us put together a custom-tailored quotation for you quickly.
        </p>
        <re-captcha class="m-auto" (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
        <button [disabled]="contactForm.invalid" type="submit" class="form-submit_btn">
          LET'S TALK
          <div *ngIf="isLoading" class="request-loading">
            <i class="request-loading_icon fa-solid fa-spinner-third fa-spin"></i>
          </div>
        </button>
      </form>
    </div>
  </div>
</div>
