import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@shared/services/base.service';
import { IStatus } from '@admin/modules/tour-management/models/invoice.model';
import { catchError, map } from 'rxjs';
import { ILanguageData } from '@modules/service/models/tour-service.model';

export interface IPhoneNumberCode {
  name: string;
  dial_code: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseService {
  constructor(private HttpClient: HttpClient) {
    super(HttpClient);
  }

  getPhoneCode() {
    // return this.HttpClient.get<IPhoneNumberCode[]>('assets/json/phoneNumberCode.json');
    return this.get<IPhoneNumberCode[]>('helpers/area-codes', 'v1');
  }

  getExchangeRate() {
    return this.get<unknown>('payment/exchange-rate', 'v1');
  }

  getStatus(type: string) {
    return this.get<IStatus[]>('status', 'v2', { type }).pipe(
      map((res) =>
        res.filter((item) => {
          return item.name !== ('Void' as any);
        })
      )
    );
  }

  getLanguage() {
    return this.HttpClient.get<ILanguageData>('assets/json/language.json').pipe(
      map((res) => res.languageData),
      catchError((err) => {
        return [];
      })
    );
  }
}
