<script src="order-summary.component.ts"></script>
<div class="order-summary-wrapper">
  <div *ngIf="isCartHidden" class="order-summary-box">
    <ng-container *ngIf="isLoading">
      <div class="text-center">
        <span nz-icon [nzType]="'loading'"></span>
      </div>
    </ng-container>
    <div *ngIf="!isLoading" class="tour-detail">
      <div class="d-flex gap-3 mb-12px">
        <ng-container *ngIf="tourDetail?.images?.length !== 0">
          <img class="max-w-48px max-h-48px" [src]="tourDetail.images[0].src" alt="" />
        </ng-container>
        <div>
          <h1 class="tour-detail-title">{{ tourDetail?.tour?.name }}</h1>
          <p *ngIf="tourDetail?.duration?.day; else daytrip" class="tour-detail-content">
            {{ tourDetail?.duration?.day }} day{{ tourDetail?.duration?.day! > 1 ? 's' : '' }} /{{
              tourDetail?.duration?.night
            }}
            night{{ tourDetail?.duration?.night! > 1 ? 's' : '' }}
          </p>
          <ng-template #daytrip>
            <p *ngIf="dayTripDuration" class="tour-detail-content">
              {{ dayTripDuration }}
            </p>
          </ng-template>
        </div>
      </div>
      <div class="tour-detail-info">
        <div class="d-flex flex-gap-8px mb-8px">
          <img src="assets/icons/icon-user.svg" alt="" />
          <div class="d-flex flex-gap-4px">
            <p *ngFor="let data of tourDetailSummary; let last = last" class="">
              <span *ngIf="!last">
                <ng-container *ngIf="data.quantity.quantity > 1; else elseBlock">
                  <ng-container [ngSwitch]="data.ageGroup.name">
                    <span *ngSwitchCase="'Adult'"> {{ data.quantity.quantity }} Adults,</span>
                    <span *ngSwitchCase="'Child'"> {{ data.quantity.quantity }} Children,</span>
                    <!--                    <span *ngSwitchCase="'Infant'"> {{ data.quantity.quantity }} Infants,</span>-->
                  </ng-container>
                </ng-container>
                <ng-template #elseBlock>
                  {{ data.quantity.quantity }} {{ data.ageGroup.name }},
                </ng-template>
              </span>
              <ng-container *ngIf="last">
                <span *ngIf="data.quantity.quantity > 1; else lessthanone"
                  >{{ data.quantity.quantity }} Infants</span
                >
                <ng-template #lessthanone>
                  <span>{{ data.quantity.quantity }} {{ data.ageGroup.name }}</span>
                </ng-template>
              </ng-container>
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-gap-8px">
            <img src="assets/icons/icon-calendar.svg" alt="" />
            <p class="fs-14px">{{ selectedDate }}</p>
          </div>
          <!--          <a [routerLink]="'/tour-services/package-tour/' + tourDetail?.id" class="edit-tour-link"-->
          <!--            >Edit tour</a-->
          <a (click)="openTourCart()" class="edit-tour-link">Edit tour</a>
        </div>
      </div>
      <hr />
      <div class="tour-detail-qty">
        <div *ngFor="let data of tourDetailSummary" class="d-flex justify-content-between">
          <p class="quantity">{{ data.quantity.quantity }} {{ data.ageGroup.name }}</p>
          <h1 class="price">${{ data.price | number: '1.0-2' }}</h1>
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-between align-items-center">
        <p class="quantity">TOTAL PRICE</p>
        <h1 class="price">${{ totalPrice }}</h1>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isCartHidden">
    <app-tour-cart
      (onClickPaymentHidden)="closeTourCart($event)"
      [tourDetail]="tourDetail"
    ></app-tour-cart>
  </ng-container>
  <div class="order-text-banner">
    <div class="d-flex flex-column gap-3">
      <h1 class="title">Book with confidence</h1>
      <ng-container *ngFor="let data of textContent">
        <div class="d-flex flex-gap-12px align-items-start">
          <img [src]="data?.imgPath" alt="" />
          <div class="text-content">
            <h1 class="text-content-title">{{ data.title }}</h1>
            <p class="text-content-description">{{ data.description }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
