import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterLayoutComponent {
  call(phoneNumber: string) {
    window.location.href = 'tel:' + phoneNumber;
  }

  paymentLogo: string[] = [
    'assets/logo/visa.png',
    'assets/logo/master-card.png',
    'assets/logo/jcb.png',
    'assets/logo/american-express.png',
    'assets/logo/one-pay.png'
  ];

  scrollToContactUS() {
    UtilsService.scrollToId('contact-us');
  }
}
