import { inject, Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '@shared/services/base.service';

export interface ApiResponse {
  code: number;
  message: string;
  data: any[] | any;
}

export function captchaValidator(control: AbstractControl): ValidationErrors | null {
  return control.value ? null : { captchaNotResolved: true };
}
@Injectable({
  providedIn: 'root'
})
export class ContactUsService extends BaseService {
  private _FormBuilder = inject(FormBuilder);

  initContactForm(): FormGroup {
    return this._FormBuilder.group({
      fullName: ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
      email: ['', [Validators.required, Validators.email]],
      compName: [''],
      guestNumbers: [''],
      question: [''],
      captcha: ['', captchaValidator]
    });
  }

  onSubmit(contactForm: FormGroup): Observable<any> {
    const submitForm = contactForm.value;
    // return this._http
    //   .post<any>(`https://wowtrip.vn:8442/api/v1/contact-us`, submitForm, httpOptions)
    //   .pipe(
    //     map((res) => res.code === '200'),
    //     catchError(() => of(false))
    //   );
    return this.postFullData<any>('contact-us', 'v1', submitForm);
  }
}
