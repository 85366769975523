import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { MainLayoutComponent } from '@modules/layouts/main-layout/main-layout.component';
import { CheckLoginGuard } from './guards/check-login.guard';
import { UnauthorizedPageComponent } from '@admin/auth/components/unauthorized-page/unauthorized-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: 'about-us', pathMatch: 'full' },
      {
        path: 'about-us',
        loadChildren: async () => (await import('@modules/about-us/about-us.module')).AboutUsModule
      },
      {
        path: 'successful-events',
        loadChildren: async () =>
          (await import('@modules/our-successful-events/our-successful-events.module'))
            .OurSuccessfulEventsModule
      },
      {
        path: 'tour-services',
        loadChildren: async () => (await import('@modules/service/service.module')).ServiceModule
      },
      {
        path: 'mice-and-events',
        loadChildren: async () =>
          (await import('@modules/corporate-mice-events/cooperate-mice-events.module'))
            .CooperateMiceEventsModule
      },
      {
        path: 'transport',
        loadChildren: async () =>
          (await import('@modules/transport/transport.module')).TransportModule
      },
      {
        path: 'cart',
        loadChildren: async () => (await import('@modules/booking/booking.module')).BookingModule
      },
      {
        path: 'payment-result',
        loadChildren: async () =>
          (await import('@modules/payment-results/payment-results-routing/payment-results.module'))
            .PaymentResultsModule
      }
    ]
  },
  {
    path: 'manage',
    title: 'Wowtrip - Admin',
    data: {
      // customBreadcrumb: 'Manage'
    },
    canActivate: [CheckLoginGuard],
    loadChildren: async () => (await import('@admin/admin.module')).AdminModule
  },
  {
    path: 'auth',
    loadChildren: async () => (await import('@admin/auth/auth.module')).AuthModule
  },
  { path: 'unauthorized', component: UnauthorizedPageComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
      // preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
