import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITourDetail } from '@modules/service/models/tour-service.model';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TourServicesService } from '@modules/service/services/tour-services.service';
import { Subscription } from 'rxjs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import * as moment from 'moment';
import { TourCartComponent } from '@shared/components/tour-cart/tour-cart.component';

@Component({
  selector: 'app-order-summary',
  standalone: true,
  imports: [CommonModule, RouterLink, NzIconModule, TourCartComponent],
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSummaryComponent implements OnInit {
  tourDetailSummary: any;
  isLoading: boolean = false;
  private sub: Subscription;
  tourDetailId: string;
  tourDetail: ITourDetail;
  totalPrice: number;
  day: number;
  selectedDate: string;
  isCartHidden: boolean = true;
  textContent: any[] = [
    {
      imgPath: 'assets/icons/checkout-1.svg',
      title: 'Save up to 70% off',
      description:
        'We negotiate directly with our travel partners to secure exclusive offers you won’t find elsewhere.'
    },
    {
      imgPath: 'assets/icons/checkout-2.svg',
      title: 'Flexible bookings',
      description: 'Travel with peace of mind with our flexible cancellation policies.'
    },
    {
      imgPath: 'assets/icons/checkout-3.svg',
      title: 'Local travel guides and inspiration',
      description: 'Be inspired by expert recommendations and local travel tips'
    }
  ];

  constructor(
    private _route: ActivatedRoute,
    private _TourServicesService: TourServicesService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getDataFromLocalStorage();

    this.sub = this._route.params.subscribe((params) => {
      this.tourDetailId = params['id'];
      this.getTourDetail(this.tourDetailId);
    });
  }

  get dayTripDuration() {
    if (this.tourDetail?.duration && !this.tourDetail?.duration?.day) {
      return this.tourDetail?.duration as unknown as string;
    }
    return null;
  }

  getDataFromLocalStorage() {
    this.tourDetailSummary = JSON.parse(localStorage.getItem('ageGroupData') || '[]');
    console.log(this.tourDetailSummary);
    this.totalPrice = JSON.parse(localStorage.getItem('totalPrice') || '1');
    const date = localStorage.getItem('selectedDate');
    if (date) {
      const formatDate = moment(date, 'YYYY-MM-DDTHH:mm:ss').format('ddd, MMM DD, YYYY. h:mm A');
      this.selectedDate = formatDate;
    }
  }

  getTourDetail(id: string) {
    this.isLoading = true;
    this._TourServicesService.getTourDetail(id).subscribe((res) => {
      this.tourDetail = res;
      this.getTourDay(this.tourDetail);
      this.isLoading = false;
      this._cdr.detectChanges();
    });
  }

  getTourDay(tourDetail: ITourDetail) {
    this.day = this._TourServicesService.getDate(tourDetail);
    this._cdr.detectChanges();
  }

  openTourCart() {
    this.isCartHidden = !this.isCartHidden;
  }

  closeTourCart(value: boolean) {
    this.isCartHidden = value;
    this.getDataFromLocalStorage();

    this.sub = this._route.params.subscribe((params) => {
      this.tourDetailId = params['id'];
      this.getTourDetail(this.tourDetailId);
    });
  }
}
