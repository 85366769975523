import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { LoadingService } from '@shared/services/loading.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class MainLayoutComponent implements OnInit {
  private _LoadingService: LoadingService = inject(LoadingService);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  isLoading: boolean;

  constructor() {}

  ngOnInit() {
    this._LoadingService.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading) => {
      console.log({ isLoading });
      this.isLoading = isLoading;
      this._cdr.detectChanges();
    });
  }
}
