<div class="custom-popup-wrapper">
  <div class="popup-header">
    <div>
      <div class="heading">Contact</div>
      <p class="sub-title">
        We'll use this information to send you confirmation and updates about your booking
      </p>
    </div>
    <button class="button-icon-secondary" (click)="close()">
      <i class="fa-light fa-xmark"></i>
    </button>
  </div>
  <mat-dialog-content class="custom-mat-dialog-content" cdk-scrollable>
    <div class="tour-detail-information tour-detail">
      <div class="d-flex gap-3">
        <img style="max-width: 48px" [src]="data.images[0]?.src" alt="" />
        <div>
          <h1 class="tour-detail-title">{{ data?.tour?.name }}</h1>
          <p class="tour-detail-content">
            {{ data?.duration?.day }} day{{ data?.duration?.day! > 1 ? 's' : '' }} /{{
              data?.duration?.night
            }}
            night{{ data?.duration?.night! > 1 ? 's' : '' }}
          </p>
        </div>
      </div>
    </div>
    <form class="d-flex flex-column gap-3" [formGroup]="contactForm">
      <div class="form-container row">
        <div class="form-input col-lg-6 col-sm-12">
          <label class="input-label required">First Name</label>
          <div class="input-text_wrapper">
            <input
              appFormValidate
              validationMsgId="customerName"
              type="text"
              class="custom-input large"
              placeholder="Type your first & last name *"
              formControlName="firstName"
            />
          </div>
        </div>
        <div class="form-input col-lg-6 col-sm-12">
          <label class="input-label required">Last Name</label>
          <div class="input-text_wrapper">
            <input
              appFormValidate
              validationMsgId="lastName"
              type="text"
              class="custom-input large"
              placeholder="Type your email address *"
              formControlName="lastName"
            />
          </div>
        </div>
      </div>
      <div class="form-container">
        <div style="flex: 1" class="form-input">
          <label class="input-label required">Email</label>
          <div class="input-text_wrapper">
            <input
              appFormValidate
              validationMsgId="customerEmail"
              type="text"
              class="custom-input large"
              placeholder="Type your email address *"
              formControlName="email"
            />
          </div>
        </div>
      </div>
      <!--      <div nz-row [nzGutter]="[16, 16]">-->
      <!--        <div nz-col nzXs="10" nzSm="10" nzMd="8" nzLg="6" nzXl="6">-->
      <!--          <div class="custom-input-wrapper" [class.error]="contactForm?.get('phoneCountryCode')?.touched && contactForm?.get('phoneCountryCode')?.invalid">-->
      <!--            <label class="required">Phone number</label>-->
      <!--            <div class="custom-select">-->
      <!--              <nz-select-->
      <!--                nzShowSearch-->
      <!--                nzPlaceHolder="Select"-->
      <!--                class="large w-full"-->
      <!--                formControlName="phoneCountryCode"-->
      <!--              >-->
      <!--                <ng-container *ngFor="let option of phoneNumberCountryCode ?? []">-->
      <!--                  <nz-option-->
      <!--                    [nzLabel]="'(' + option.dial_code + ') ' + option.name"-->
      <!--                    [nzValue]="option.dial_code"-->
      <!--                  ></nz-option>-->
      <!--                </ng-container>-->
      <!--              </nz-select>-->
      <!--            </div>-->
      <!--            <ng-container *ngIf="contactForm?.get('phoneCountryCode')?.touched && contactForm?.get('phoneCountryCode')?.invalid">-->
      <!--              <span class="error-text" *ngIf="contactForm?.get('phoneCountryCode')?.hasError('required')">Phone code is required</span>-->
      <!--            </ng-container>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div nz-col nzXs="14" nzSm="14" nzMd="16" nzLg="10" nzXl="10">-->
      <!--          <div-->
      <!--            class="custom-input-wrapper" [class.error]="contactForm?.get('customerPhone')?.touched && contactForm?.get('customerPhone')?.invalid"-->
      <!--            [class.disabled-half]="contactForm.get('phonePrefix')?.invalid"-->
      <!--          >-->
      <!--            <label class="required" for="customerPhone" style="visibility: hidden"></label>-->
      <!--            <input-->
      <!--              class="custom-input large w-100"-->
      <!--              type="text"-->
      <!--              id="customerPhone"-->
      <!--              placeholder="Enter phone number"-->
      <!--              formControlName="customerPhone"-->
      <!--              name="customerPhone"-->
      <!--            />-->
      <!--            <ng-container *ngIf="contactForm?.get('customerPhone')?.touched && contactForm?.get('customerPhone')?.invalid">-->
      <!--              <span class="error-text" *ngIf="contactForm?.get('customerPhone')?.hasError('required')">Phone number is required</span>-->
      <!--            </ng-container>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="form-container">
        <div style="flex: 1" class="form-input">
          <label class="input-label required">Phone number</label>
          <div class="d-flex flex-gap-8px">
            <div class="input-text_wrapper custom-select-container">
              <nz-select
                [nzDropdownMatchSelectWidth]="false"
                nzShowSearch
                nzAllowClear
                [nzBorderless]="true"
                formControlName="phoneCountryCode"
              >
                <ng-container *ngFor="let data of phoneNumberCountryCode">
                  <nz-option
                    [nzValue]="data.dial_code"
                    [nzLabel]="'(' + data.dial_code + ') ' + data.name"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </div>
            <div class="input-text_wrapper w-100">
              <input
                appFormValidate
                validationMsgId="customerPhone"
                type="number"
                class="custom-input large"
                placeholder="Phone number *"
                formControlName="phone"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex gap-2">
        <input type="checkbox" id="checkbox" class="custom-checkbox" />
        <label class="custom-checkbox-label" for="checkbox"
          >Receive SMS updates about your booking. Message rates may apply.</label
        >
      </div>
      <div class="form-container">
        <div style="flex: 1" class="form-input">
          <label class="input-label">Special requirement</label>
          <div class="input-text_wrapper">
            <app-tour-important-note-form
              [configCkEditor]="ckeditorConfig"
              #tourImportantNoteFormComponent
            ></app-tour-important-note-form>
          </div>
        </div>
      </div>
      <div class="form-container m-auto">
        <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-wrapper" align="end">
    <button class="button-secondary" (click)="close()">Cancel</button>
    <button
      style="width: 107px"
      [disabled]="contactForm.invalid"
      (click)="onContactFormSubmit()"
      class="button-primary"
    >
      <i *ngIf="isLoading" class="fa-solid fa-spinner-third fa-spin"></i>
      <span *ngIf="!isLoading">SEND FORM</span>
    </button>
  </mat-dialog-actions>
</div>
