import { Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private _ViewportScroller: ViewportScroller) {}

  static scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  static scrollToId(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  static formatDate(date: string, formatType?: string): string {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      if (formatType) {
        return momentDate.format(formatType);
      } else {
        return momentDate.format('YYYY-MM-DD');
      }
    }
    return ''
  }
}
